<template>
  <div
    class="product-detail-price d-md-flex flex-column justify-content-between d-none my-3"
  >
    <div v-if="product">
      <div v-if="startDate" class="protect discountPriceTime mb-2">
        <img
          class="ml-2"
          :src="require('../../../../assets/images/discount.png')"
          alt="ضمانت"
        />ّ
        <div class="d-flex flex-column mr-4">
          <b class="d-block text-center">شروع تخفیف از:</b>
          <flip-countdown
            v-if="startDate"
            :deadline="startDate"
          ></flip-countdown>
        </div>
      </div>
      <div class="protect mb-2">
        <img
          class="ml-2"
          :src="require('../../../../assets/images/prize.png')"
          alt="ضمانت"
        />
        <div>ضمانت اصالت و سلامت فیزیکی کالا</div>
      </div>
      <div class="protect mb-2">
        <img
          class="ml-2"
          :src="require('../../../../assets/images/destination.png')"
          alt="حمل و نقل"
        />
        <div>خرید بالای یک میلیون ارسال رایگان</div>
      </div>
      <div class="protect mb-3">
        <img
          class="ml-2"
          :src="require('../../../../assets/images/express.png')"
          alt="ارسال به گرگان"
        />
        <div>ارسال رایگان به کردکوی</div>
      </div>
    </div>
    <span v-else>
      <b-skeleton
        class="w-100 ml-2"
        :class="i != 3 ? 'mb-2' : 'mb-3'"
        height="50px"
        v-for="i in 3"
        :key="i"
      ></b-skeleton>
      <b-skeleton class="w-100 ml-2"></b-skeleton>
      <b-skeleton class="w-75 ml-2"></b-skeleton>
    </span>
    <div>
      <div
        v-if="
          productVarietyAvailable == 'available' &&
            product.hasVarietyAvailable.in_storage != 0
        "
        class="text-right d-flex align-items-baseline justify-content-center px-3 flex-column"
        :class="{ 'pt-3': theme == 2 }"
      >
        <div style="width: 100%" class="d-flex justify-content-between">
          <span class="customer-price">قیمت مصرف کننده</span>
          <div
            v-if="product.hasVarietyAvailable.discountPercent == 0"
            class=" text-color-999 fontsize15"
          >
            <span class="fontsize20 weight-bold text-color-red ml-1">{{
              product.hasVarietyAvailable.price | price
            }}</span
            >تومان
          </div>
          <div v-else class="line-through text-color-999 fontsize12">
            <span class="fontsize14 weight-bold ml-1">{{
              product.hasVarietyAvailable.price | price
            }}</span
            >تومان
          </div>
        </div>
        <div
          v-if="product.hasVarietyAvailable.discountPercent != 0"
          class=" text-color-999 fontsize15 mr-2 text-left w-100"
        >
          <span class="fontsize20 weight-bold text-color-red ml-1">{{
            product.hasVarietyAvailable.discountPrice | price
          }}</span
          >تومان
        </div>
      </div>
      <div
        v-else-if="
          productVarietyAvailable == 'available' &&
            product.hasVarietyAvailable.in_storage == 0
        "
        class="text-center"
      >
        <span class="fontsize12 weight-bold text-color-red ml-1"
          >موجودی این محصول به پایان رسیده است.</span
        >
      </div>
      <button
        id="add-to-cart-btn"
        v-if="product != null && product.hasVarietyAvailable != null"
        @click="cartToAdd"
        :disabled="
          disabled ||
          product.check_status == 'unavailable' ||
          product.hasVarietyAvailable.in_storage == 0
            ? true
            : false
        "
        type="button"
        class="bg-color-red w-100 p-lg-3 p-2 mt-3 border-radius15"
      >
        <CartSvg />
        <span class="text-white weight-bold fontsize14"
          >افزودن به سبد خرید</span
        >
      </button>
      <button
        v-else
        :disabled="true"
        type="button"
        class="bg-color-red w-100 p-lg-3 p-2 mt-3 border-radius15"
      >
        <CartSvg />
        <span class="text-white weight-bold fontsize14"
          >افزودن به سبد خرید</span
        >
      </button>
    </div>
  </div>
</template>

<script>
import CartSvg from "../svgs/CartSvg";
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "PriceBox",
  components: {
    CartSvg,
    FlipCountdown,
  },
  props: {
    productVarietyAvailable: String,
    product: Object,
    varieties: Array,
    disabled: Boolean,
    theme: {
      type: String,
      default: "",
    },
  },
  methods: {
    cartToAdd() {
      this.$parent.cartToAdd
        ? this.$parent.cartToAdd()
        : this.$parent.$parent.cartToAdd();
    },
  },
  computed: {
    startDate() {
      if (!this.varieties && !this.varieties.length) {
        return null;
      }
      let selectedDiscount = null;
      this.varieties.forEach((v) => {
        if (v.discounts) {
          v.discounts.forEach((d) => {
            selectedDiscount = d;
          });
        }
      });
      if (!selectedDiscount) {
        return null;
      }
      let now = new Date();
      let discountTime = new Date(selectedDiscount.start_date);

      if (now.getTime() > discountTime.getTime()) {
        return false;
      } else {
        return window
          .moment(selectedDiscount.start_date)
          .format("YYYY/MM/DD HH:mm");
      }
    },
  },
};
</script>

<style scoped>
.protect {
  color: #526181;
  align-items: center;
  height: 55px;
  border-radius: 10px;
  border: 1px solid #626262;
  padding: 5px;
  display: flex;
  background: rgba(51, 26, 26, 0.05);
}

.customer-price {
  color: #a4a4a4;
  font-size: 16px;
}

.product-detail-price {
  background: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
  font-size: 14px;
  height: max-content;
}
</style>
<style>
.discountPriceTime .flip-card {
  font-size: 1.2rem !important;
}
@media screen and (min-width: 1250px) {
  .discountPriceTime .flip-card {
    font-size: 1.4rem !important;
  }
}
@media screen and (min-width: 1000px) {
  .discountPriceTime .flip-card {
    font-size: 1.3rem !important;
  }
}
@media screen and (min-width: 700px) {
  .discountPriceTime .flip-card {
    font-size: 1.1rem !important;
  }
}
@media screen and (max-width: 500px) {
  .discountPriceTime .flip-card {
    font-size: 1rem !important;
  }
  .discountPriceTime .flip-clock {
    padding: 0 !important;
  }
}
@media screen and (max-width: 380px) {
  .discountPriceTime .flip-card {
    font-size: 0.9rem !important;
  }
}
@media (min-width: 1000px) {
  .discountPriceTime .flip-clock__piece {
    margin: 1px !important;
  }

  .discountPriceTime .container.flip-clock {
    padding: 0 !important;
    margin-top: 5px;
  }
}
.flip-clock {
  direction: ltr;
}
/** up */
.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
  background: #9995c4 !important;
  color: white !important;
}
/** down */
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__bottom-4digits,
.flip-card__back-bottom-4digits {
  background: #595695 !important;
  color: #f1f1f1 !important;
}
.discountPriceTime .flip-clock__slot {
  display: none !important;
}
</style>
