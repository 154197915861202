<template>
    <div id="product-comments">
      <span style="font-size: 1.5rem">نظرات</span>
      <div class="d-flex justify-content-between px-0 pt-1 px-sm-3 pb-4">
            <div class=" mb-3 w-100 text-center">
                <!-- <h6 class="text-color-444 mb-4">چطور می توانم درمورد این کالا نظر بدهم؟</h6> -->
                <span v-if="comments.length == 0">
                    <p class="fontsize14 text-color-666 text-justify line-height2 mb-2 text-center">
                        دیدگاهی برای این محصول ثبت نشده است.
                    </p>
                    <h5 class="fontsize15 text-color-444 text-justify line-height2 mb-4 font-weight-bold text-center">
                       اولین نفری باشید که دیدگاهی را ارسال می‌کند.
                    </h5>
                </span>

                <button type="button" v-b-toggle.collapse-1
                    class="send-comment-btn fontsize13 text-white py-3 px-5 bg-color-themeBlue border-radius10 ">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2 12.015C2 6.74712 6.21 2 12.02 2C17.7 2 22 6.65699 22 11.985C22 18.1642 16.96 22 12 22C10.36 22 8.54 21.5593 7.08 20.698C6.57 20.3876 6.14 20.1572 5.59 20.3375L3.57 20.9384C3.06 21.0986 2.6 20.698 2.75 20.1572L3.42 17.9139C3.53 17.6034 3.51 17.2729 3.35 17.0125C2.49 15.4301 2 13.6975 2 12.015ZM10.7 12.015C10.7 12.7261 11.27 13.2969 11.98 13.307C12.69 13.307 13.26 12.7261 13.26 12.025C13.26 11.314 12.69 10.7431 11.98 10.7431C11.28 10.7331 10.7 11.314 10.7 12.015ZM15.31 12.025C15.31 12.7261 15.88 13.307 16.59 13.307C17.3 13.307 17.87 12.7261 17.87 12.025C17.87 11.314 17.3 10.7431 16.59 10.7431C15.88 10.7431 15.31 11.314 15.31 12.025ZM7.37 13.307C6.67 13.307 6.09 12.7261 6.09 12.025C6.09 11.314 6.66 10.7431 7.37 10.7431C8.08 10.7431 8.65 11.314 8.65 12.025C8.65 12.7261 8.08 13.2969 7.37 13.307Z"
                            fill="#fff" />
                    </svg>
                    برای ارسال نظر کلیک کنید
                </button>
                <b-collapse id="collapse-1" class="mt-2">
                    <b-card class="box-shaddow20 border-0 border-radius10">
                        <productCommentForm :productId="productId" />
                    </b-card>
                </b-collapse>
            </div>
        </div>
        <div v-if="comments != ''" class="user-comments px-sm-3 px-2 py-sm-2 pt-4">
            <div v-for="comment in comments" :key="comment.id" class="user-comments-item pb-3 mb-3">
                <div class="d-flex flex-wrap align-items-baseline justify-content-between mb-2">
                    <span class="fontsize13 weight-bold text-color-444">{{ comment.name }} <span
                            class="text-color-999 fontsize11 mr-1"> /
                            {{ comment.created_at | commentDate }}</span></span>
                            <button class="product-rating pt-2 pr-2">
                                <b-form-rating v-model="comment.rate" readonly variant="warning" size="sm" class="mb-2">
                                </b-form-rating>
                              </button>
                </div>
                <p class="text-color-666 fontsize12 text-justify line-height2">
                     {{ comment.comment }}
                </p>
                <div v-if="comment.response != null" class="mr-3 bg-light px-3 py-2 border-radius10">
                    <span class="fontsize13 weight-bold text-color-444">مدیر </span>
                        <p class="text-color-666 fontsize12 text-justify line-height2">
                            {{ comment.response }}
                       </p>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import productCommentForm from './productCommentForm'

    export default {
        components: {
            productCommentForm
        },
        props: ['comments', 'productId'],
        filters: {
            commentDate(date) {
                return window.moment(date).format('dddd jDD jMMMM jYYYY')
            },
        },
    }
</script>
