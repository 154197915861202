<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form
      id="send-product-comment-form"
      @submit.prevent="(e) => handleSubmit(() => submitComment(e))"
      class=""
    >
      <div
        class="d-flex flex-xl-row flex-column flex-wrap justify-content-right fontsize13 mb-2"
      >
        <ValidationProvider
          name="name"
          mode="passive"
          class="mb-3 text-right"
          rules="required"
          v-slot="v"
        >
          <input
            name="name"
            @change="v.validate"
            type="text"
            :readonly="
              $store.state.header.user &&
              $store.state.header.user.first_name != null &&
              $store.state.header.user.last_name != null
                ? true
                : false
            "
            class="rtl-placeholder rtl bg-color-ea  py-2 px-3 border-radius10 ml-xl-2 "
            placeholder="نام کامل *"
            v-model="comment.name"
          />
          <span class="text-right text-danger mt-2 mx-2">{{
            v.errors[0]
          }}</span>
        </ValidationProvider>

        <div
          class="product-rating product-comment-rating pt-2 pr-2"
          style="width: 100px;"
        >
          <b-form-rating
            name="rate"
            v-model="comment.rating"
            variant="warning"
            class="mb-2"
          >
          </b-form-rating>
        </div>
      </div>
      <ValidationProvider
        name="text"
        mode="passive"
        class="mb-3 text-right"
        rules="required"
        v-slot="v"
      >
        <textarea
          @change="v.validate"
          type="text"
          class="bg-color-ea border-radius10 py-2 px-3 w-100 mb-2 fontsize13"
          name="comment"
          cols="30"
          rows="10"
          placeholder="دیدگاه شما... *"
        ></textarea>
        <span class="text-right text-danger mt-2">{{ v.errors[0] }}</span>
      </ValidationProvider>
      <div class="text-left">
        <button
          type="submit"
          :disabled="sendingRequest"
          class="send-comment-btn fontsize13 text-white py-2 px-5  bg-color-themeBlue border-radius10 "
        >
          ارسال نظر
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "productCommentForm",
  props: {
    productId: {
      type: Number,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      comment: {
        rating: 4,
        name: "",
        email: "",
      },
      sendingRequest: false,
    };
  },
  mounted() {
    if (this.$store.state.header.user) {
      this.$store.state.header.user.first_name != null &&
      this.$store.state.header.user.last_name != null
        ? (this.comment.name =
            this.$store.state.header.user.first_name +
            " " +
            this.$store.state.header.user.last_name)
        : "";
      this.$store.state.header.user.email != null
        ? (this.comment.email = this.$store.state.header.user.email)
        : "";
    }
  },
  methods: {
    submitComment(e) {
      this.sendingRequest = true;
      let formData = new FormData(e.target);
      this.$axios
        .post(`/api/send/comment/${this.productId}`, formData)
        .then((response) => {
          this.$root.success_notification("کامنت شما با موفقیت ثبت شد");
          this.resetForm();
        })
        .catch((error) => {
          this.$root.error_notification(error);
        })
        .finally(() => {
          this.sendingRequest = false;
        });
    },
    resetForm() {
      this.$(
        "form#send-product-comment-form input, form#send-product-comment-form textarea"
      ).val("");
    },
  },
};
</script>
<style scoped>
.send-comment-btn {
  width: 220px !important;
}
@media screen and (max-width: 400px) {
  .send-comment-btn {
    width: 100% !important;
  }
}
</style>
<style>
.product-comment-rating .b-icon.bi {
  width: 18px !important;
  height: 18px !important;
}
</style>
